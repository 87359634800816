import { useEffect, lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/utils/ScrollToTop";
import Login from "./components/common/Login";
import Register from "./components/common/Register";
import NotFound from "./components/common/NotFound";
import AdminDashboard from "./pages/AdminDashboard";
import ProtectedRoute from "./components/common/ProtectedRoute";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import Loader from "./components/common/Loader";
import MainLayout from "./components/layout/MainLayout";

// Lazy load pages without prefetch
const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Services = lazy(() => import("./pages/Services"));
const Blog = lazy(() => import("./pages/Blog"));
const Contact = lazy(() => import("./pages/Contact"));
const BlogDetail = lazy(() => import("./pages/BlogDetail"));
const Events = lazy(() => import("./pages/Events"));

const App: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    // Load components in background during idle time
    if ("requestIdleCallback" in window) {
      (window as any).requestIdleCallback(() => {
        import("./pages/About");
        import("./pages/Services");
        import("./pages/Blog");
        import("./pages/Contact");
        import("./pages/BlogDetail");
        import("./pages/Events");
      });
    }

    // Handle anchor links smoothly
    const links = document.querySelectorAll('a[href^="#"]');

    const handleAnchorClick = (e: Event) => {
      e.preventDefault();

      const link = e.currentTarget as HTMLAnchorElement;
      const targetId = link.getAttribute("href");
      if (!targetId || targetId === "#") return;

      const targetElement = document.querySelector(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    };

    links.forEach((link) => {
      link.addEventListener("click", handleAnchorClick);
    });

    return () => {
      links.forEach((link) => {
        link.removeEventListener("click", handleAnchorClick);
      });
    };
  }, [location]);

  return (
    <>
      <ScrollToTop />
      <ToastContainer limit={3} />
      <Suspense fallback={<Loader fullScreen={true} showText={true} />}>
        <Routes>
          <Route
            path="/"
            element={
              <MainLayout>
                <Home />
              </MainLayout>
            }
          />
          <Route
            path="/about"
            element={
              <MainLayout>
                <About />
              </MainLayout>
            }
          />
          <Route
            path="/services"
            element={
              <MainLayout>
                <Services />
              </MainLayout>
            }
          />
          <Route
            path="/blog"
            element={
              <MainLayout>
                <Blog />
              </MainLayout>
            }
          />
          <Route
            path="/blog/:id"
            element={
              <MainLayout>
                <BlogDetail />
              </MainLayout>
            }
          />
          <Route
            path="/events"
            element={
              <MainLayout>
                <Events />
              </MainLayout>
            }
          />
          <Route
            path="/contact"
            element={
              <MainLayout>
                <Contact />
              </MainLayout>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/admin-dashboard"
            element={
              <ProtectedRoute>
                <MainLayout showFooter={false}>
                  <AdminDashboard />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
