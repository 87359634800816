import React from "react";
import { motion } from "framer-motion";

interface LoaderProps {
  width?: string;
  height?: string;
  fullScreen?: boolean;
  color?: string;
  showText?: boolean;
  isLoading?: boolean;
  blur?: boolean;
}

const Loader: React.FC<LoaderProps> = ({
  width = "80px",
  height = "80px",
  fullScreen = false,
  color = "#ff3366",
  isLoading = true,
  blur = true,
}) => {
  if (!isLoading) return null;

  const containerClass = fullScreen
    ? `fixed inset-0 flex items-center justify-center w-screen h-screen z-[9999] ${
        blur ? "backdrop-blur-sm bg-white/30" : "bg-white"
      }`
    : "flex items-center justify-center w-full py-8";

  return (
    <div className={containerClass}>
      <motion.div
        className="flex flex-col items-center justify-center"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.2 }}
      >
        {/* Enhanced elegant spinner */}
        <div className="relative" style={{ width, height }}>
          {/* Background glow effect */}
          <motion.div
            className="absolute inset-0 rounded-full"
            style={{
              background: `radial-gradient(circle, ${color}20 0%, transparent 70%)`,
              transform: "scale(1.5)",
            }}
            animate={{
              scale: [1.5, 1.8, 1.5],
              opacity: [0.3, 0.5, 0.3],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          />

          {/* Outer circle with gradient */}
          <motion.div
            className="absolute inset-0 rounded-full"
            style={{
              background: `conic-gradient(from 0deg, ${color}00, ${color}40)`,
              borderRadius: "50%",
            }}
            animate={{ rotate: 360 }}
            transition={{
              duration: 3,
              ease: "linear",
              repeat: Infinity,
            }}
          />

          {/* Main spinning arc */}
          <motion.div
            className="absolute inset-0 rounded-full"
            style={{
              borderRadius: "50%",
              border: `3px solid transparent`,
              borderTopColor: color,
              borderRightColor: `${color}90`,
              borderLeftColor: `${color}30`,
            }}
            animate={{ rotate: 360 }}
            transition={{
              duration: 0.8,
              ease: "linear",
              repeat: Infinity,
            }}
          />

          {/* Inner pulsing dot */}
          <motion.div
            className="absolute"
            style={{
              width: "25%",
              height: "25%",
              borderRadius: "50%",
              background: `radial-gradient(circle, ${color} 0%, ${color}80 50%, ${color}00 100%)`,
              top: "50%",
              left: "50%",
              x: "-50%",
              y: "-50%",
            }}
            animate={{
              scale: [1, 1.2, 1],
              opacity: [0.8, 1, 0.8],
            }}
            transition={{
              duration: 1,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          />

          {/* Synergy Logo with glow */}
          {fullScreen && (
            <motion.div
              className="absolute inset-0 flex items-center justify-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.3 }}
            >
              <motion.span
                className="text-2xl font-bold drop-shadow-lg"
                style={{ color }}
                animate={{
                  textShadow: [
                    `0 0 10px ${color}40`,
                    `0 0 15px ${color}60`,
                    `0 0 10px ${color}40`,
                  ],
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              >
                S
              </motion.span>
            </motion.div>
          )}
        </div>

        {/* Optional loading text with shimmer effect */}
        {fullScreen && (
          <motion.div
            className="mt-4 text-sm font-medium relative overflow-hidden"
            style={{ color }}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.2 }}
          >
            <span>Loading</span>
            <motion.span
              animate={{ opacity: [0, 1, 0] }}
              transition={{ duration: 1.5, repeat: Infinity }}
            >
              ...
            </motion.span>
          </motion.div>
        )}
      </motion.div>
    </div>
  );
};

export default Loader;
